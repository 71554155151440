exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-ebike-en-js": () => import("./../../../src/pages/ebike.en.js" /* webpackChunkName: "component---src-pages-ebike-en-js" */),
  "component---src-pages-ebike-js": () => import("./../../../src/pages/ebike.js" /* webpackChunkName: "component---src-pages-ebike-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inverno-js": () => import("./../../../src/pages/inverno.js" /* webpackChunkName: "component---src-pages-inverno-js" */),
  "component---src-pages-itinerari-js": () => import("./../../../src/pages/itinerari.js" /* webpackChunkName: "component---src-pages-itinerari-js" */),
  "component---src-pages-itineraries-en-js": () => import("./../../../src/pages/itineraries.en.js" /* webpackChunkName: "component---src-pages-itineraries-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-update-js": () => import("./../../../src/pages/success-update.js" /* webpackChunkName: "component---src-pages-success-update-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-winter-en-js": () => import("./../../../src/pages/winter.en.js" /* webpackChunkName: "component---src-pages-winter-en-js" */),
  "component---src-templates-ebike-template-js": () => import("./../../../src/templates/ebike-template.js" /* webpackChunkName: "component---src-templates-ebike-template-js" */),
  "component---src-templates-winter-template-js": () => import("./../../../src/templates/winter-template.js" /* webpackChunkName: "component---src-templates-winter-template-js" */)
}

